import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
    overflow-x: hidden;
  }

  #root {
    min-height: 100%;
    min-width: 100%;
  }

  .ant-btn,
  .ant-input, 
  .ant-input-number,
  .ant-input-affix-wrapper, 
  .ant-select-single .ant-select-selector, 
  .ant-select-multiple .ant-select-selector, 
  .ant-picker, 
  .ant-checkbox .ant-checkbox-inner,
  .ant-collapse {
    border-radius: 0;
  }

  /* remove background color for Chrome autocomplete */
  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus, 
  input:-webkit-autofill:active {
      -webkit-background-clip: text;
      transition: background-color 5000s ease-in-out 0s;
      box-shadow: inset 0 0 20px 20px #fff;
  }

  .ant-checkbox-group {
    display: block;
  }

  .text-error {
    color: #ff4d4f;
  }

  .text-success {
    color: #25B24B !important;
  }

  .ql-editor {
    min-height: 150px;
  }

  .react-quill-en .ql-editor {
    font-family: 'Poppins';
  }

  .english-field {
    text-align: left;
    direction: ltr;
    font-family: 'Poppins';
  }

  .react-tel-input {
    text-align: left;
    direction: ltr;
  }

  .ant-row .react-tel-input {
    font-family: 'Poppins';
  }


  .ant-row-rtl .react-tel-input {
    font-family: 'Tajawal';
  }

  .arabic-field, .react-quill-ar .ql-editor {
    text-align: right;
    direction: rtl;
    font-family: 'Tajawal';
  }

  .react-quill-ar ul, .react-quill-ar ol {
    padding-right: 1.5em;
  }

  .react-quill-ar li:not(.ql-direction-rtl)::before {
    margin-left: 0 !important;
  }

  .form-control[type=file]:not(:disabled):not([readonly]) {
    font-size: 14px;
  }
`;
